<template>
    <div>
        <p class="text text-margin">{{ $t('faq.gse.step4.giveAplaylist') }}</p>
        <p class="text">{{ $t('faq.gse.step4.chooseM3U') }}</p>
        <p class="text">{{ $t('faq.gse.step4.syntax') }}</p>
        <p class="text">{{ $t('faq.gse.step4.channelsList') }}</p>
        <a class="text link" :href="link" target="_blank">{{ link }}</a>
        <p class="text text-margin text-margin-top">{{ $t('faq.gse.step4.doubleCheck') }}</p>
        <p class="text text-margin">{{ $t('faq.gse.step4.onTheNext') }}</p>
        <p class="text text-margin">{{ $t('faq.gse.step4.thenYouCanClick') }}</p>
        <div class="screenshot">
            <img src="../assets/screen-3.png" alt="" class="step-image">
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['brandConfig']),
        link() {
            return `${this.brandConfig.tvLink}/api/list/USERNAME/PASSWORD`
        }
    }
}
</script>
<style scoped>
@import "../../../components/styles/guides-default-styles.css";
.text-margin-top {
    margin-top: 32px;
}
</style>
